export const SCREEN_WIDTHS = {
  DESKTOP: '1920px',
  LAPTOP: '1440px',
  PHONE: '640px',
  TABLET: '1024px',
};

export const COLORS = {
  blueAccent: '#769FCD',
  blueLight: '#E4EFF9',
  blueNav: '#D6E6F2',
  dark: '#1b262c',
  primary: '#0f0f55',
  test: '#a21515',
  white: '#FFFFFF',
};

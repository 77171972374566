import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { COLORS, SCREEN_WIDTHS } from '../../../../constants/styleConstants';
import { TLinkUI } from '../Header.styles';

type TLine = {
  $isOpen: boolean;
};

type TMenu = {
  $isOpen: boolean;
};

export const BurgerMenuUI = styled.div`
  display: none;

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    display: flex;
  }
`;

export const BurgerButton = styled.div`
  width: 44px;
  height: 32px;
  display: block;

  transition: all 0.5s ease-in;
  z-index: 10;

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    margin-left: 16px;
  }
`;

const LineBase = styled.div`
  width: 24px;
  margin: 6px 8px;

  border: 1px solid ${COLORS.dark};
  border-radius: 1px;

  transition: all 0.5s ease-in;
`;

export const LineFirst = styled(LineBase)<TLine>`
  width: ${({ $isOpen }) => ($isOpen ? '30px' : '24px')};
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: rotate(45deg);
      transform-origin: 6px 4px;
    `}
`;

export const LineMiddle = styled(LineBase)<TLine>`
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      display: none;
      opacity: 0;
    `}
`;

export const LineLast = styled(LineBase)<TLine>`
  width: ${({ $isOpen }) => ($isOpen ? '30px' : '24px')};

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: rotate(-45deg);
      transform-origin: 16px 2px;
    `}
`;

export const BurgerBar = styled.div<TMenu>`
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 2 * 56px);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 120px 56px 56px;

  background-color: ${COLORS.blueLight};

  transition: transform 0.3s ease-in-out;
  transform: ${({ $isOpen }) =>
    $isOpen ? 'translateX(0)' : 'translateX(100%)'};

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    width: calc(100% - 2 * 40px);
    padding: 80px 40px 40px;
  }
}
`;

export const BurgerUl = styled.ul`
  list-style: none;

  height: 434px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    height: 378px;
  }
`;

export const HelpBurgerUl = styled.ul`
  list-style: none;

  width: 300px;
  height: 152px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: 56px;

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    width: 280px;
    height: 116px;
    margin-top: 32px;
  }
`;

export const LinkUI = styled(Link)<TLinkUI>`
  text-decoration: none;

  font-family: 'Ubuntu_medium', 'sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;

  color: ${COLORS.dark};
  //background-color: {({ $isSelected }) => ($isSelected ? '' : '')};

  &:hover {
    color: ${COLORS.white};
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    font-family: 'Ubuntu_medium', 'sans-serif';
    font-size: 18px;
    line-height: 21px;

    &:hover {
      color: ${COLORS.white};
    }
  }
`;

export const HelpLinkUI = styled(Link)<TLinkUI>`
  text-decoration: none;

  width: 300px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Ubuntu_light', 'sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;

  color: ${COLORS.dark};
  border-radius: 50px;
  //background-color: {({ $isSelected }) => $isSelected ? '' : ''};
  background-color: ${COLORS.white};

  &:hover {
    color: ${COLORS.white};
    background-color: ${COLORS.blueAccent};
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    width: 280px;
    height: 50px;

    font-size: 18px;
    line-height: 21px;
  }
`;

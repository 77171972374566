import { Link } from 'react-router-dom';
import styled from 'styled-components';

// @ts-ignore
import header_logo from '../../../assets/img/logo/header_logo.svg';
import { COLORS, SCREEN_WIDTHS } from '../../../constants/styleConstants';

export type TLinkUI = {
  $isSelected: boolean;
};

export const HeaderUI = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    width: calc(100% - 2 * 48px);
    padding: 32px 48px;
    justify-content: space-between;
    flex-direction: row;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    width: calc(100% - 2 * 16px);
    padding: 24px 16px;
  }
`;

export const LogoActions = styled.div`
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    height: 56px;
  }
`;

export const LogoContainer = styled.div`
  width: 530px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${SCREEN_WIDTHS.LAPTOP}) {
    width: 400px;
  }

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    width: 260px;
    flex-direction: row;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    width: 240px;
  }
`;

export const HeaderLogo = styled.a`
  width: 100px;
  height: 70px;
  margin-left: 170px;

  background-image: url(${header_logo});
  background-size: cover;

  @media (max-width: ${SCREEN_WIDTHS.LAPTOP}) {
    margin-left: 60px;
  }

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    width: 80px;
    height: 56px;
    margin-left: 0;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    margin-left: 0;
  }
`;

export const Phone = styled.a`
  text-decoration: none;

  font-family: 'Ubuntu_light', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;

  color: ${COLORS.dark};

  @media (max-width: ${SCREEN_WIDTHS.LAPTOP}) {
    margin-right: 16px;
  }

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    margin-right: 0;

    font-family: 'Ubuntu_light', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const ButtonsContainer = styled.ul`
  list-style: none;

  min-width: 770px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  background-color: ${COLORS.blueLight};

  @media (max-width: ${SCREEN_WIDTHS.LAPTOP}) {
    min-width: 660px;
  }

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    display: none;
  }
`;

export const LinkUI = styled(Link)<TLinkUI>`
  text-decoration: none;
  box-sizing: border-box;

  height: 50px;
  display: flex;
  align-items: center;
  padding: 14px 20px;

  font-family: 'Ubuntu_light', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;

  color: ${COLORS.dark};

  border: none;
  border-radius: 50px;
  // background-color: {({ $isSelected }) => $isSelected ? '' : ''};
  background-color: ${COLORS.white};

  &:hover {
    cursor: copy;
    color: ${COLORS.white};
    background-color: ${COLORS.blueAccent};
  }
`;

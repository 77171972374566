import React from 'react';

import { Footer } from '../../shared/Footer';
import { Header } from '../../shared/Header';

import { StubFooterContainer, StubUI } from './Stub.styles';
import { StubFooter } from './StubFooter';
import { StubMain } from './StubMain';

export const Stub = () => (
  <StubUI>
    <Header />
    <StubMain />
    <StubFooterContainer>
      <StubFooter />
      <Footer />
    </StubFooterContainer>
  </StubUI>
);

// @ts-ignore
import address from '../assets/img/logo/footer-address.png';
// @ts-ignore
import mail from '../assets/img/logo/footer-letter.png';
// @ts-ignore
import phone from '../assets/img/logo/footer-phone.png';

export const CONTACTS = [
  {
    id: 'phone',
    logo: phone,
    text: '+375 29 710 01 03',
    url: 'tel:+375 29 710 01 03',
  },
  {
    id: 'mail',
    logo: mail,
    text: 'diaconia.by@gmail.com',
    url: 'mailto:diaconia.by@gmail.com',
  },
  {
    id: 'address',
    logo: address,
    text: '220004, Республика Беларусь г. Минск, ул. Раковская, 26, кабинет 1',
    url: 'https://yandex.by/maps/-/CDF-b88f',
  },
];

import React from 'react';

import { Footer } from '../../shared/Footer';
import { Header } from '../../shared/Header';
import { StubFooterContainer } from '../Stub/Stub.styles';
import { StubFooter } from '../Stub/StubFooter';
import { StubMain } from '../Stub/StubMain';

import { MainUI } from './Main.styles';
// import { TitleBox } from './TitleBox';

export const Main = () => (
  <MainUI>
    <Header />
    {/* <TitleBox /> */}
    <StubMain />
    <StubFooterContainer>
      <StubFooter />
      <Footer />
    </StubFooterContainer>
  </MainUI>
);

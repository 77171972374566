import styled from 'styled-components';

import { SCREEN_WIDTHS } from '../../../constants/styleConstants';

export const StubUI = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

export const StubFooterContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

// *******!!!!!!!! style example
export const TEST = styled.div`
  background-color: aquamarine;

  @media (max-width: ${SCREEN_WIDTHS.DESKTOP}) {
    background-color: wheat;
  }

  @media (max-width: ${SCREEN_WIDTHS.LAPTOP}) {
    background-color: rebeccapurple;
  }

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    background-color: rosybrown;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    background-color: aqua;
  }
`;

import React from 'react';

import { MainDiv, MainText } from './Main.styles';

export const StubMain = () => {
  return (
    <MainDiv>
      <MainText>Ведется разработка данного раздела.</MainText>
    </MainDiv>
  );
};

import React from 'react';
import { useLocation } from 'react-router-dom';

import { HELP_NAV_ITEMS } from '../../../constants/navItems';

import { BurgerMenu } from './BurgerMenu';
import {
  ButtonsContainer,
  HeaderLogo,
  HeaderUI,
  LinkUI,
  LogoActions,
  LogoContainer,
  Phone,
} from './Header.styles';
import { Navbar } from './Navbar';

export const Header = () => {
  const location = useLocation();

  return (
    <HeaderUI>
      <LogoActions>
        <LogoContainer>
          <HeaderLogo href="https://mercy.by/" />
          <Phone href="tel:+375 29 710 01 03">+375 29 710 01 03</Phone>
        </LogoContainer>

        <ButtonsContainer>
          {HELP_NAV_ITEMS.map((item) => (
            <li key={item[0]}>
              <LinkUI
                to={item[0]}
                $isSelected={location.pathname === item[0]}
              >
                {item[1]}
              </LinkUI>
            </li>
          ))}
        </ButtonsContainer>

        <BurgerMenu />
      </LogoActions>

      <Navbar />
    </HeaderUI>
  );
};

import styled from 'styled-components';

import { COLORS, SCREEN_WIDTHS } from '../../../../constants/styleConstants';

export const MainDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 8px auto;
`;

export const MainText = styled.span`
  font-family: 'Ubuntu_light', 'sans-serif';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  color: ${COLORS.primary};

  @media (max-width: ${SCREEN_WIDTHS.LAPTOP}) {
    font-size: 20px;
    line-height: 23px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    font-size: 14px;
    line-height: 16px;
  }
`;

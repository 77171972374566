import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { HELP_NAV_ITEMS, NAV_ITEMS } from '../../../../constants/navItems';

import {
  BurgerBar,
  BurgerButton,
  BurgerMenuUI,
  BurgerUl,
  HelpBurgerUl,
  HelpLinkUI,
  LineFirst,
  LineLast,
  LineMiddle,
  LinkUI,
} from './BurgerMenu.styles';

export const BurgerMenu: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <BurgerMenuUI>
      <BurgerButton onClick={toggleMenu}>
        <LineFirst $isOpen={isMenuOpen} />
        <LineMiddle $isOpen={isMenuOpen} />
        <LineLast $isOpen={isMenuOpen} />
      </BurgerButton>

      <BurgerBar $isOpen={isMenuOpen}>
        <BurgerUl>
          {NAV_ITEMS.map((item) => (
            <li key={item[0]}>
              <LinkUI
                to={item[0]}
                $isSelected={location.pathname === item[0]}
              >
                {item[1]}
              </LinkUI>
            </li>
          ))}
        </BurgerUl>

        <HelpBurgerUl>
          {HELP_NAV_ITEMS.map((item) => (
            <li key={item[0]}>
              <HelpLinkUI
                to={item[0]}
                $isSelected={location.pathname === item[0]}
              >
                {item[1]}
              </HelpLinkUI>
            </li>
          ))}
        </HelpBurgerUl>
      </BurgerBar>
    </BurgerMenuUI>
  );
};

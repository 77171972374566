import styled from 'styled-components';

// @ts-ignore
import footerLAPTOP from '../../../assets/img/bg/footer/footer_1024_1439.png';
// @ts-ignore
import footerDESKTOP from '../../../assets/img/bg/footer/footer_1440+.png';
// @ts-ignore
import footerPHONE from '../../../assets/img/bg/footer/footer_320_639.png';
// @ts-ignore
import footerTABLET from '../../../assets/img/bg/footer/footer_640_1023.png';
import { COLORS, SCREEN_WIDTHS } from '../../../constants/styleConstants';

type TLogo = {
  $img: string;
  $imgHover?: string;
};

export const FooterUI = styled.div`
  width: 100%;
  height: 300px;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 80px 170px;

  background-image: url(${footerDESKTOP});
  background-size: cover;

  box-sizing: border-box;

  @media (max-width: ${SCREEN_WIDTHS.LAPTOP}) {
    padding: 80px 60px;
    background-image: url(${footerLAPTOP});
  }

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    height: 254px;
    padding: 56px 48px;
    background-image: url(${footerTABLET});
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    height: 276px;
    flex-direction: column;
    align-items: flex-start;
    padding: 48px 24px;
    background-image: url(${footerPHONE});
  }
`;

export const ContactsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const ContactContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    width: 344px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    width: 90%;
    word-break: normal;
  }
`;

export const Logo = styled.a<TLogo>`
  min-width: 40px;
  min-height: 40px;

  background-image: url(${({ $img }) => $img});
  background-size: cover;

  &:hover {
    background-image: url(${({ $imgHover, $img }) => $imgHover || $img});
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    min-width: 32px;
    min-height: 32px;
  }
`;

export const Text = styled.div`
  margin-left: 16px;

  font-family: 'Ubuntu_light', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;

  color: ${COLORS.dark};

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    font-size: 16px;
    line-height: 18px;
  }
`;

export const LinksContainer = styled.div`
  width: 152px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    width: 128px;
  }
`;
